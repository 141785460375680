import { Component } from 'react';
import detectNearestBrowserLocale from 'detect-nearest-browser-locale';
import supportedLocales from '../supported-locales';

class IndexPage extends Component {
    componentDidMount() {
        const locale =
            detectNearestBrowserLocale(supportedLocales.map(({ id }) => id)) ||
            'en';

        window.location = `/${locale}`;
    }

    render() {
        return null;
    }
}

export default IndexPage;
