const supportedLocales = [
    {
        id: 'en',
        label: 'English',
    },
    {
        id: 'es',
        label: 'Español',
    },
    {
        id: 'fr',
        label: 'Français',
    },
    {
        id: 'it',
        label: 'Italiano',
    },
    {
        id: 'nl',
        label: 'Nederlands',
    },
];

export default supportedLocales;
